<template>
  <div class="row">
    <div class="col-md-12">
      <h1>Tags</h1>

      <div class="card">
        <div class="card-body">
          <div
            class="table-responsive"
            tabindex="1"
            style="overflow: hidden; outline: none"
          >
            <DataTable :value="tags" responsiveLayout="scroll" :paginator="true" :rows="25" stripedRows
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10,25,50]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            editMode="cell"
            class="editable-cells-table"
            @cellEditComplete="onCellEditComplete"
            dataKey="tagId"
            ref="dt"
            v-model:selection="selectedTags"
            v-model:filters="filters"
            :globalFilterFields="['tagId', 'value','type']"
            :loading="loading"
            >

            <template #header>
              <div class="row">
                <div class="col">
                  <button
                    class="btn btn-outline-light me-2"
                    type="button"
                    aria-expanded="false"
                    @click="newTag"
                    title="Nova tag"
                    v-if="hasPermission(permissionsConstants.TAGS_CREATE)"
                  >
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </button>

                  <button
                    class="btn btn-outline-light me-2"
                    type="button"
                    aria-expanded="false"
                    @click="refresh"
                    title="Atualizar"
                  >
                    <font-awesome-icon class="me-1" :icon="['fas', 'redo']" />
                  </button>

                  <button
                    class="btn btn-outline-light me-2"
                    type="button"
                    :disabled="!countCheck1"
                    @click="multipleRemove"
                    title="Remover"
                    v-if="hasPermission(permissionsConstants.TAGS_DELETE)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>

                  <button
                    class="btn btn-outline-light me-2"
                    type="button"
                    id="dropdownMenuButton1"
                    aria-expanded="false"
                    :disabled="!countCheck2"
                    title="Agrupar tags"
                    @click="merge"
                    v-if="hasPermission(permissionsConstants.TAGS_UPDATE)"
                  >
                    <i class="far fa-object-group"></i>
                  </button>

                  <button
                    class="btn btn-outline-light me-2"
                    type="button"
                    id="dropdownMenuButton1"
                    aria-expanded="false"
                    @click="exportCSV($event)"
                    title="Download"
                  >
                    <i class="fas fa-download"></i>
                  </button>
                </div>

                <div class="col text-right">
                  <span class="p-input-icon-left" style="float: right;">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                  </span>
                </div>
              </div>

            </template>
              <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
              <Column field="tagId" header="ID" :sortable="true"></Column>
              <Column field="value" header="Tag" :sortable="true">
                <template #editor="slotProps" v-if="hasPermission(permissionsConstants.TAGS_UPDATE)">
                  <InputText :class="form-control" :modelValue="slotProps.data[slotProps.column.props.field]" @update:modelValue="onCellEdit($event, slotProps)" />
                </template>
              </Column>
              <Column field="type" header="Tipo" :sortable="true">
                <template #editor="slotProps" v-if="hasPermission(permissionsConstants.TAGS_UPDATE)">
                  <InputText :class="form-control" :modelValue="slotProps.data[slotProps.column.props.field]" @update:modelValue="onCellEdit($event, slotProps)" />
                </template>
              </Column>
              <Column field="count" header="Uso" :sortable="true"></Column>
              <Column header="Ações">
                <template #body="slotProps">
                  <i class="fas fa-trash-alt" @click="singleRemove(slotProps)" v-if="hasPermission(permissionsConstants.TAGS_DELETE)"></i>
                </template>

              </Column>

          </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import axios from 'axios';
import { FilterMatchMode } from 'primevue/api';
import { mapActions, mapState, mapGetters } from 'vuex';
import permissionsConstants from '../../constants/permissions';

export default {
  components: { DataTable, Column,
    InputText
  },
  computed: {
    ...mapState(['apiEndpoint', 'loading']),
    ...mapGetters(['hasPermission']),
    countCheck1() {
      if (this.selectedTags.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    countCheck2() {
      if (this.selectedTags.length > 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      tags: [],
      showTags: [],
      offset: 200,
      selectedTags: [],
      sortColumn: 'value',
      sortOrder: 'asc',
      textEdit: null,
      fieldEdit: null,
      filters: null
    };
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    onCellEdit(newVal, slotProps) {
      this.textEdit = newVal;
      this.fieldEdit = slotProps.field;
    },
    newTagListener(event) {
      if (event.code == 'Insert') {
        this.newTag();
      }
    },
    async merge() {
      // Use the first value and type as template
      let value = this.selectedTags[0].value;
      let type = this.selectedTags[0].type;

      value = prompt('Entre com a nova tag:', value);
      type = prompt('Entre com o novo tipo:', type);

      const data = {
        ids: [],
        value: value,
        type: type
      };

      for (let i = 0; i < this.selectedTags.length; i++) {
        data.ids.push(this.selectedTags[i].tagId)
      }

      const url = `${this.apiEndpoint}/tags/merge`;
      try {
        await axios.post(url, data);
        this.refresh();
      } catch {
        this.$toast.add({severity:'error', summary: 'Já há um termo cadastrado.', life: 3000});
      }
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      }
    },
    async onCellEditComplete(event) {
      if (this.textEdit) {
        let body = new Object;

        body = event.newData;

        body[this.fieldEdit] = this.textEdit;

        try {
          await axios.put(`${this.apiEndpoint}/tags/${body.tagId}`, body);
          this.tags[event.index][this.fieldEdit] = this.textEdit;
          this.textEdit = null;
          this.fieldEdit = null;
          event.preventDefault
        } catch {
          this.$toast.add({severity:'error', summary: 'Houve um erro ao salvar a informação. Por favor, tente novamente.', life: 3000});
        } finally {
          event.preventDefault();
        }
      }
    },
    exportCSV() {
      // console.log(this.$refs);
      this.$refs.dt.exportCSV();
    },
    // handleScroll() {
    //   // Any code to be executed when the window is scrolled
    //   // this.isUserScrolling = (window.scrollY > 0);
    //   // console.log('calling handleScroll');
    //   let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;

    //   if (bottomOfWindow) {
    //     this.offset += 200;
    //     this.sort();
    //   }
    // },
    async newTag() {
      const value = prompt("Entre com a nova tag:");

      if (value !== "" && value !== null) {
        const type = prompt("Entre com o tipo:");

        const body = {
          value: value,
          type: type,
        };

        try {
          const res = await axios.post(`${this.apiEndpoint}/tags`, body);

          const item = {
            tagId: res.data.tagId,
            value: res.data.value,
            type: res.data.type,
            count: 0
          };

          this.tags.push(item);
          this.$toast.add({severity:'success', summary: 'Tag adicionada', life: 3000});
        } catch (err) {
          this.$toast.add({severity:'error', summary: 'Já há um termo cadastrado com esse nome.', life: 3000});
        }
      }
    },
    async refresh() {
      this.setLoadingOn();
      this.offset = 200;
      this.tags = [];

      try {
        const { data } = await axios.get(`${this.apiEndpoint}/tags`);
        this.tags = data;
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao ler os termos.', life: 3000});
      } finally {
        this.setLoadingOff();
      }
    },
    multipleRemove() {
      if (confirm("Deseja remover as tags marcadas?")) {

        const ids = new Array()

        this.selectedTags.forEach(el => ids.push(el.tagId))

        this.remove(ids);
      }
    },
    singleRemove(property) {
      const tagId = property.data.tagId;

      if (confirm("Deseja remover a tag?")) {
        const ids = [tagId];

        this.remove(ids);
      }
    },
    async remove(ids) {
      const string = ids.join(',');

      try {
        await axios.delete(`${this.apiEndpoint}/tags/${string}`);

        this.tags = this.tags.filter(tag => ids.indexOf(tag.tagId) < 0)

        if (ids.length === 1) {
          this.$toast.add({severity:'success', summary: 'Tag removida', life: 3000});
        } else if (ids.length > 1) {
          this.$toast.add({severity:'success', summary: 'Tags removidas', life: 3000});
        }

        this.selectedTags = [];
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao remover os termos.', life: 3000});
      }
    },
  },
  mounted() {
    this.refresh();
    window.addEventListener('keydown', this.newTagListener);
  },
  created() {
    this.initFilters();
    this.permissionsConstants = permissionsConstants;
  },
  unmounted() {
    window.removeEventListener("keydown", this.newTagListener);
  },
};
</script>

<style scoped>
.fas {
  cursor: pointer;
}
</style>